import { useWallet } from '@solana/wallet-adapter-react';
import clsx from 'clsx';
import Head from 'next/head';
import Wrapper from '../components/common/Wrapper';
import Header from '../components/Header';
import { LinkWallet } from '../components/LinkWallet';
import {
  SolanaWalletButton,
  SolanaWalletContext,
} from '../components/SolanaWallet';
import DialectLogo from '../icons/DialectApp.svg';

const SolanaWalletButtonEnhanced = () => {
  const { connected } = useWallet();

  return (
    <SolanaWalletButton
      className={clsx(
        'dialect-new-button',
        connected && 'dialect-new-button--secondary'
      )}
    />
  );
};

export default function LinkWalletPage() {
  return (
    <SolanaWalletContext>
      <Head>
        <title>Dialect - Linking Wallet</title>
      </Head>
      <div className="w-full text-white flex flex-col md:h-screen bg-wallet-link pb-4">
        <Header
          logo={<DialectLogo className="h-[32px]" />}
          CTA={<SolanaWalletButtonEnhanced />}
        />
        <Wrapper className="h-full">
          <LinkWallet />
        </Wrapper>
      </div>
    </SolanaWalletContext>
  );
}
