import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';

export const ScanQrCoreUserTip = () => {
  return (
    <div className="flex flex-row justify-center gap-2 px-6">
      <FontAwesomeIcon fixedWidth icon={faCircleInfo} color="#6F7276" />
      <p className="text-dark-300 text-[11px]">
        Having trouble scanning? Try reducing glare, holding your phone level
        with the QR code, and slowly moving away to about one foot (0.3 m).
      </p>
    </div>
  );
};
