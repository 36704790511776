import { useCallback, useEffect, useState } from 'react';
import { useDialectWallet, WalletStatesWrapper } from '@dialectlabs/react-ui';
import clsx from 'clsx';
import { DialectSolanaWalletAdapter } from '@dialectlabs/react-sdk-blockchain-solana';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { HEADER_STYLES, SectionLayout } from './styles';
import { generateWalletAssertion } from './generateWalletAssertion/generateWalletAssertion';
import { LinkWalletCheckList } from './LinkWalletCheckList';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-regular-svg-icons';
import { ErrorModal } from './ErrorModal';
import { DataMatrixCodeModal } from './DataMatrixCodeModal';
import { DataMatrixCode } from './DataMatrixCode';
import { ScanQrCoreUserTip } from './ScanQrCoreUserTip';

export const LinkWalletStepper = ({
  dialectSolanaWalletAdapter,
}: {
  dialectSolanaWalletAdapter: DialectSolanaWalletAdapter | null;
}) => {
  const {
    walletConnected: { get: isWalletConnected, set: setWalletConnected },
    connectionInitiatedState: { get: isConnectionInitiated },
    hardwareWalletForcedState: { get: hardwareWalletForced },
    isSigningFreeTransactionState: { set: setIsSigningFreeTransaction },
    isSigningMessageState: { set: setIsSigningMessage },
  } = useDialectWallet();
  const [dataMatrixCode, setDataMatrixCode] = useState('');
  const [isMessageSigned, setIsMessageSigned] = useState(false);

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  const generateAssertionAndSetQRCode = useCallback(
    async (isLedger?: boolean) => {
      try {
        const assertion = await generateWalletAssertion(
          dialectSolanaWalletAdapter!,
          isLedger
        );
        setIsMessageSigned(true);
        const qrCodeString = Buffer.from(JSON.stringify(assertion)).toString(
          'base64'
        );
        setDataMatrixCode(qrCodeString);
      } catch (e) {
        setErrorModalOpen(true);
        console.error(e);
      } finally {
        setIsSigningFreeTransaction(false);
        setIsSigningMessage(false);
      }
    },
    [
      dialectSolanaWalletAdapter,
      setIsSigningFreeTransaction,
      setIsSigningMessage,
    ]
  );

  useEffect(
    function signMessageAndGenerateQRCode() {
      if (
        !isWalletConnected ||
        !isConnectionInitiated ||
        !dialectSolanaWalletAdapter ||
        dataMatrixCode.length
      ) {
        return;
      }

      if (hardwareWalletForced) {
        setIsSigningFreeTransaction(true);
        generateAssertionAndSetQRCode(hardwareWalletForced);
      } else {
        setIsSigningMessage(true);
        generateAssertionAndSetQRCode();
      }
    },
    [
      dataMatrixCode.length,
      dialectSolanaWalletAdapter,
      generateAssertionAndSetQRCode,
      hardwareWalletForced,
      isConnectionInitiated,
      isWalletConnected,
      setIsSigningFreeTransaction,
      setIsSigningMessage,
    ]
  );

  const solanaWallet = useSolanaWallet();
  const handleTryAgain = useCallback(() => {
    solanaWallet.disconnect();
    setErrorModalOpen(false);
  }, [solanaWallet]);

  const [isQRCodeFullScreenOpen, setQRCodeFullScreenOpen] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <SectionLayout>
        <div className={HEADER_STYLES}>Link Wallet To Do</div>
        <div className="flex flex-col gap-5">
          <LinkWalletCheckList
            isMessageSigned={isMessageSigned}
            isWalletConnected={isWalletConnected}
          />
        </div>
      </SectionLayout>
      <SectionLayout
        className={clsx(
          isWalletConnected &&
            '[&>div]:items-start [&>div>*]:w-full [&>div>*]:max-w-none [&>div>*]:text-left [&>div>*]:opacity-100',
          isErrorModalOpen && 'hidden'
        )}
      >
        <WalletStatesWrapper>
          <div className="relative">
            {dataMatrixCode.length > 0 && (
              <React.Fragment>
                <div className="flex justify-center">
                  <DataMatrixCode dataMatrixCode={dataMatrixCode} />
                </div>
                <span className="!w-[44px] !h-[44px] absolute top-[-15px] right-0">
                  <button
                    className="w-[44px] h-[44px] rounded-full flex items-center justify-center bg-dark-700"
                    onClick={() => setQRCodeFullScreenOpen(true)}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      fixedWidth
                      icon={faUpRightAndDownLeftFromCenter}
                      color="#FFFFFF"
                    />
                  </button>
                </span>
                <div className="mt-4">
                  <ScanQrCoreUserTip />
                </div>
              </React.Fragment>
            )}
          </div>
        </WalletStatesWrapper>
      </SectionLayout>
      <ErrorModal isOpen={isErrorModalOpen} onClose={handleTryAgain} />
      <DataMatrixCodeModal
        isOpen={isQRCodeFullScreenOpen}
        onClose={() => setQRCodeFullScreenOpen(false)}
        dataMatrixCode={dataMatrixCode}
      />
    </div>
  );
};
