import {
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import { DialectSolanaWalletAdapter } from '@dialectlabs/blockchain-sdk-solana';
import nacl from 'tweetnacl';

export interface Data {
  payload: Uint8Array;
}

export interface SignedData {
  data: Uint8Array;
  proof: Uint8Array;
}

export interface Signer {
  alg: string;
  publicKey: Uint8Array;

  sign(data: Data): Promise<SignedData>;
}

class SolanaOffChainMessageSigner implements Signer {
  constructor(private readonly adapter: DialectSolanaWalletAdapter) {}

  alg = 'solana-offchain-message';
  publicKey = this.adapter.publicKey!.toBytes();

  async sign(document: Data): Promise<SignedData> {
    const signature = await this.adapter.signMessage!(document.payload);
    if (
      !nacl.sign.detached.verify(document.payload, signature, this.publicKey)
    ) {
      throw new Error('Invalid signature');
    }
    return {
      data: document.payload,
      proof: signature,
    };
  }
}

const MEMO_PROGRAM_ID = new PublicKey(
  'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'
);

export class SolanaOfflineTxSigner implements Signer {
  connection: Connection;
  alg = 'solana-memo-tx';
  publicKey = this.adapter.publicKey!.toBytes();
  private static rpcURL =
    'https://dialect-dialect-5d55.mainnet.rpcpool.com/5599a9b6-44a1-4ac1-be55-c8a7220904f9';

  constructor(private readonly adapter: DialectSolanaWalletAdapter) {
    this.connection = new Connection(SolanaOfflineTxSigner.rpcURL, 'confirmed');
  }

  async sign(document: Data): Promise<SignedData> {
    const latestBlockhash = await this.connection.getLatestBlockhash();
    const tx = new Transaction({
      feePayer: this.adapter.publicKey,
      blockhash: latestBlockhash.blockhash,
      lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
    });
    tx.add(
      new TransactionInstruction({
        programId: MEMO_PROGRAM_ID,
        keys: [],
        data: Buffer.from(document.payload),
      })
    );
    const signedTx = await this.adapter.signTransaction!(tx);
    const isVerifiedSignature = signedTx.verifySignatures();

    if (!isVerifiedSignature) {
      throw new Error('Invalid signature');
    }

    return {
      data: document.payload,
      proof: signedTx.serialize(),
    };
  }
}

export function getSigner(
  walletAdapter: DialectSolanaWalletAdapter,
  isLedger: boolean
) {
  if (isLedger) {
    return new SolanaOfflineTxSigner(walletAdapter);
  }
  return new SolanaOffChainMessageSigner(walletAdapter);
}
