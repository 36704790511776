import { useMemo } from 'react';
import {
  faPenSwirl,
  faQrcode,
  IconDefinition,
  faBolt,
  faCheck,
  faExternalLink,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '../common/Link';

type Props = {
  isWalletConnected: boolean;
  isMessageSigned: boolean;
};

export const LinkWalletCheckList = ({
  isWalletConnected,
  isMessageSigned,
}: Props) => {
  const checkList = useMemo<
    Array<{ icon: IconDefinition; text: React.ReactNode; checked: boolean }>
  >(() => {
    return [
      {
        icon: faBolt,
        text: 'Connect your preferred Wallet',
        checked: isWalletConnected,
      },
      {
        icon: faPenSwirl,
        text: 'Sign message request with Wallet',
        checked: isMessageSigned,
      },
      {
        icon: faQrcode,
        text: (
          <>
            Scan QR Code with{' '}
            <Link href="https://www.dialect.to/" target="_blank">
              Dialect{' '}
              <FontAwesomeIcon icon={faExternalLink} size="2xs" fixedWidth />
            </Link>
          </>
        ),
        checked: false,
      },
    ];
  }, [isMessageSigned, isWalletConnected]);
  return (
    <div className="flex flex-col gap-5">
      {checkList.map((item, index) => {
        return (
          <div
            key={`${index}-${item.text}`}
            className="flex gap-4 items-center"
          >
            <FontAwesomeIcon
              size="xl"
              fixedWidth
              icon={item.checked ? faCheck : item.icon}
              color={item.checked ? '#21D19F' : '#8537E3'}
            />
            <span className="text-[15px] lg:text-[17px]">{item.text}</span>
          </div>
        );
      })}
    </div>
  );
};
