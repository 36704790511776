import {
  defaultVariables,
  IncomingThemeVariables,
} from '@dialectlabs/react-ui';
import clsx from 'clsx';

export const HEADER_STYLES = 'text-[21px] font-bold text-white mb-6';

export const themeVariables: IncomingThemeVariables = {
  dark: {
    colors: {
      toggleBackground: 'bg-dark-500',
      toggleBackgroundActive: 'bg-primary-300',
      toggleThumb: 'bg-white',
    },
    button: `${defaultVariables.dark.button} !border-none dialect-new-button`,
    highlighted: `${defaultVariables.dark.highlighted} bg-transparent px-0 [&>span]:text-[15px] items-center`,
    textStyles: {
      header: `${defaultVariables.dark.textStyles.header} ${HEADER_STYLES}`,
      body: `${defaultVariables.dark.textStyles.body} text-[13px] text-start`,
    },
  },
};

export const SECTION_LAYOUT_STYLE =
  'w-full mx-auto lg:max-w-[490px] rounded-[16px] bg-dark-800 px-[16px] py-[24px] flex flex-col text-white';

export const SectionLayout: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <div className={clsx(SECTION_LAYOUT_STYLE, className)}>{children}</div>
  );
};
