import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import MicroModal from 'react-micro-modal';
import { HEADER_STYLES, SectionLayout } from './styles';

export const ErrorModal: React.FC<
  React.PropsWithChildren<{ isOpen: boolean; onClose: () => void }>
> = ({ isOpen, onClose }) => {
  return (
    <MicroModal
      open={isOpen}
      handleClose={onClose}
      closeOnEscapePress={true}
      closeOnOverlayClick={true}
      overrides={{
        Root: {
          className: 'z-[150]',
        },
        Overlay: {
          className: 'z-[150]',
        },
        Dialog: {
          style: {
            zIndex: 150,
            padding: 0,
            borderRadius: 0,
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      {() => {
        return (
          <SectionLayout className="lg:w-[490px]">
            <div
              className={clsx(HEADER_STYLES, '!mb-4 flex gap-4 items-center')}
            >
              <FontAwesomeIcon
                size="lg"
                fixedWidth
                icon={faWarning}
                color="#DB2763"
              />
              Error
            </div>
            <div className="text-dark-300 mb-6">
              Something went wrong, try again.
            </div>
            <button
              className="dialect-new-button dialect-new-button--secondary"
              onClick={onClose}
            >
              Confirm
            </button>
          </SectionLayout>
        );
      }}
    </MicroModal>
  );
};
