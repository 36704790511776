import Image from 'next/image';
import linkedWalletSrc from '../../images/LinkedWallet.png';
import { H1 } from '../common/primitives';
import { LinkWalletCheckList } from './LinkWalletCheckList';

export const LinkWalletPromo = () => {
  return (
    <div className="w-full h-full flex flex-col lg:flex-row items-center gap-6 lg:pb-14">
      <div className="w-11/12 sm:w-10/12 lg:w-6/12 lg:px-11">
        <Image priority src={linkedWalletSrc} alt="Linked Wallet example" />
      </div>
      <div className="w-11/12 sm:w-9/12 lg:w-6/12 space-y-4">
        <H1 className="!text-[30px] !leading-9 lg:!text-[42px] lg:!leading-tight !text-white">
          Link wallets to your Dialect account in 3 steps
        </H1>
        <p className="text-dark-300 text-[15px] lg:text-[17px]">
          Showcase your NFTs and keep your assets safe
        </p>
        <LinkWalletCheckList
          isMessageSigned={false}
          isWalletConnected={false}
        />
      </div>
    </div>
  );
};
