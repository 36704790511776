import { DialectSolanaWalletAdapter } from '@dialectlabs/react-sdk-blockchain-solana';
import { nanoid } from 'nanoid';
import nacl from 'tweetnacl';
import { getSigner } from './signing-primitives';

const statement = `Dialect wants to link your wallet with your Dialect App account.`;

class AssertionData {
  statement!: string;
  walletAddress!: string;
  expiresAt?: Date;
  nonce!: string;

  static toString(assertionData: AssertionData): string {
    return `${assertionData.statement}
    
    Wallet address: ${assertionData.walletAddress}
    Expires at: ${assertionData.expiresAt?.toISOString()}
    Nonce: ${assertionData.nonce}`;
  }

  static fromString(str: string): AssertionData {
    const statement = str.split('\n')[0];
    const walletAddress = str.match(/Wallet address: (.*)/)?.[1];
    const expiresAt = str.match(/Expires at: (.*)/)?.[1];
    const nonce = str.match(/Nonce: (.*)/)?.[1];

    if (!statement || !walletAddress || !nonce) {
      throw new Error('Invalid message');
    }
    return {
      statement,
      walletAddress,
      expiresAt: expiresAt ? new Date(expiresAt) : undefined,
      nonce,
    };
  }
}

interface SignedAssertionDto {
  data: string;
  proof: string;
  signerPublicKey: string;
  signerAlg: string;
}

export async function generateWalletAssertion(
  walletAdapter: DialectSolanaWalletAdapter,
  isLedger = false
): Promise<SignedAssertionDto> {
  if (!walletAdapter.publicKey) {
    throw new Error('Wallet not connected');
  }

  try {
    const signer = getSigner(walletAdapter, isLedger);
    const now = new Date();
    const message: AssertionData = {
      statement: statement,
      expiresAt: new Date(now.getTime() + 1000 * 60 * 5), // 5 minutes
      walletAddress: walletAdapter.publicKey.toBase58(),
      nonce: nanoid(),
    };
    const plaintext = AssertionData.toString(message);
    const bytesMessage = Buffer.from(plaintext);
    const signedData = await signer.sign({
      payload: bytesMessage,
    });
    const assertion: SignedAssertionDto = {
      data: Buffer.from(plaintext).toString('base64'),
      proof: Buffer.from(signedData.proof).toString('base64'),
      signerPublicKey: walletAdapter.publicKey.toBuffer().toString('base64'),
      signerAlg: signer.alg,
    };

    return assertion;
  } catch (e) {
    throw e;
  }
}
