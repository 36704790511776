import bwipjs, { RenderOptions } from 'bwip-js';
import Image from 'next/image';
import { useEffect, useState } from 'react';

function generateDataMatrixCanvasElement(options: Partial<RenderOptions>) {
  const canvas = document.createElement('canvas');
  return bwipjs.toCanvas(canvas, {
    bcid: 'datamatrix', // Barcode type
    text: 'Dialect', // Text to encode
    scale: 3, // 3x scaling factor
    width: 300,
    height: 300,
    includetext: true, // Show human-readable text
    textxalign: 'center', // Always good to set this
    paddingwidth: 32,
    paddingheight: 32,
    ...options,
  });
}

function toBase64(str: string) {
  return Buffer.from(str).toString('base64');
}

export const DataMatrixCode: React.FC<
  React.PropsWithChildren<{ dataMatrixCode: string; width?: number }>
> = ({ dataMatrixCode, width = 300 }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const bcid = urlParams.get('bcid') === 'qrcode' ? 'qrcode' : 'datamatrix'

  useEffect(
    function setupDataMatrixCanvasElement() {
      const canvas = generateDataMatrixCanvasElement({
        bcid,
        text: dataMatrixCode,
        width,
        height: width,
      });
      const dataUrl = canvas.toDataURL('image/png');

      setImageSrc(dataUrl);
    },
    [dataMatrixCode, width]
  );
  return (
    <div
      className={`rounded-xl background bg-white w-[${width}px] h-[${width}px]`}
    >
      {imageSrc && (
        <Image
          src={imageSrc}
          alt="data-matrix-code"
          width={width}
          height={width}
        />
      )}
    </div>
  );
};
