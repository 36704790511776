import {
  DialectNoBlockchainSdk,
  ConfigProps,
  DialectThemeProvider,
  DialectUiManagementProvider,
} from '@dialectlabs/react-ui';

import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import {
  DialectSolanaSdk,
  DialectSolanaWalletAdapter,
  SolanaConfigProps,
} from '@dialectlabs/react-sdk-blockchain-solana';
import { solanaWalletToDialectWallet } from '../../shared/utils/wallet';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { themeVariables } from './styles';
import { LinkWalletStepper } from './LinkWalletStepper';
import { LinkWalletPromo } from './LinkWalletPromo';

export const LinkWallet = () => {
  const solanaWallet = useSolanaWallet();
  const [dialectSolanaWalletAdapter, setDialectSolanaWalletAdapter] =
    useState<DialectSolanaWalletAdapter | null>(null);

  useEffect(() => {
    setDialectSolanaWalletAdapter(solanaWalletToDialectWallet(solanaWallet));
  }, [solanaWallet]);

  const DialectProviders: React.FC<{ children: React.ReactNode }> = useCallback(
    (props: { children: React.ReactNode }) => {
      const dialectConfig: ConfigProps = {
        environment: 'production',
        dialectCloud: {
          tokenStore: 'local-storage',
        },
      };

      if (dialectSolanaWalletAdapter) {
        const solanaConfig: SolanaConfigProps = {
          wallet: dialectSolanaWalletAdapter,
        };

        return (
          <DialectSolanaSdk config={dialectConfig} solanaConfig={solanaConfig}>
            {props.children}
          </DialectSolanaSdk>
        );
      }

      return <DialectNoBlockchainSdk>{props.children}</DialectNoBlockchainSdk>;
    },
    [dialectSolanaWalletAdapter]
  );

  return (
    <DialectProviders>
      <DialectUiManagementProvider>
        <DialectThemeProvider theme="dark" variables={themeVariables}>
          {solanaWallet.connected ? (
            <LinkWalletStepper
              dialectSolanaWalletAdapter={dialectSolanaWalletAdapter}
            />
          ) : (
            <LinkWalletPromo />
          )}
        </DialectThemeProvider>
      </DialectUiManagementProvider>
    </DialectProviders>
  );
};
