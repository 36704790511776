import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MicroModal from 'react-micro-modal';
import { DataMatrixCode } from './DataMatrixCode';

export const DataMatrixCodeModal: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    dataMatrixCode: string;
    onClose: () => void;
  }>
> = ({ isOpen, dataMatrixCode, onClose }) => {
  return (
    <MicroModal
      open={isOpen}
      handleClose={onClose}
      closeOnEscapePress
      closeOnOverlayClick
      overrides={{
        Root: {
          className: 'z-[150]',
        },
        Overlay: {
          className: 'z-[150]',
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
        },
        Dialog: {
          style: {
            zIndex: 150,
            padding: 0,
            borderRadius: 12,
            backgroundColor: 'transparent',
            position: 'relative',
            overflowY: 'initial',
          },
        },
      }}
    >
      {() => {
        return (
          <>
            <span className="!w-[52px] !h-[52px] absolute top-[-80px] right-[-52px]">
              <button
                className="w-[52px] h-[52px] rounded-full flex items-center justify-center bg-dark-700"
                onClick={onClose}
              >
                <FontAwesomeIcon
                  size="xl"
                  fixedWidth
                  icon={faClose}
                  color="#FFFFFF"
                />
              </button>
            </span>
            <DataMatrixCode dataMatrixCode={dataMatrixCode} width={500} />
          </>
        );
      }}
    </MicroModal>
  );
};
